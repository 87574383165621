<template>
	<div>
<!--		{{searchForm}}-->
		<el-form :model="searchForm" inline>
<!--			<el-form-item label="部门">-->
<!--				<base-tree-select style="width: 200px;" url="/Dept/listTree" val-label="name" val-key="dept_id" v-model="searchForm.dept_id" is-top :top-value="0" top-label="全部" @change="onChangeDept"></base-tree-select>-->
<!--				<type-select  style="width: 200px;" url="/Dept/listAll" :data="{state:1}" val-label="name" val-key="dept_id" v-model="searchForm.dept_id" is-top top-label="全部" @change="onChangeDept"></type-select>-->
<!--			</el-form-item>-->
			<el-form-item label="" class="form-item-auto-line-height">
				<el-radio-group v-model="time_type" @change="onChangeTimeType">
					<el-radio-button label="week">本周</el-radio-button>
					<el-radio-button label="month">本月</el-radio-button>
					<el-radio-button label="year">本年</el-radio-button>
					<el-radio-button label="other">自定义</el-radio-button>
				</el-radio-group>
				<range-time class="mg-l-10" :max-pick-days="31" @change="onChangeTime" v-if="time_type === 'other'"></range-time>
			</el-form-item>
		</el-form>
		<el-row :gutter="10" v-if="info" class="mg-b-20">
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div class="f-14" style="color: #409EFF;">新增事项</div>
					<div class="f-30" style="color: #409EFF;">{{info.need_done}}<span class="f-12 mg-l-5">件</span></div>
				</div>
			</el-col>
<!--			<el-col :span="3">-->
<!--				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">-->
<!--					<div class="f-14" style="color: #409EFF;">待审批</div>-->
<!--					<div class="f-30" style="color: #409EFF;">{{info.wait_check}}<span class="f-12 mg-l-5">件</span></div>-->
<!--				</div>-->
<!--			</el-col>-->
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div class="f-14" style="color: #409EFF;">待领办</div>
					<div class="f-30" style="color: #409EFF;">{{info.all_wait_pick}}<span class="f-12 mg-l-5">件</span></div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div class="f-14" style="color: #409EFF;">逾期未领办</div>
					<div class="f-30" style="color: #409EFF;">{{info.delay_pick}}<span class="f-12 mg-l-5">件</span></div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div style="color: #409EFF;" class="f-14">办理中</div>
					<div style="color: #409EFF;" class="f-30">{{info.wait_todo}}<span class="f-12 mg-l-5">件</span></div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div style="color: #409EFF;" class="f-14">已办结</div>
					<div style="color: #409EFF;" class="f-30">{{info.is_done}}<span class="f-12 mg-l-5">件</span></div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div style="color: #409EFF;" class="f-14">办结率</div>
					<div style="color: #409EFF;" class="f-30">{{info.need_done > 0 ? (((info.is_done / info.need_done) * 100).toFixed(2)) : '0.00'}}<span class="f-12 mg-l-5">%</span></div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div style="color: #409EFF;" class="f-14">逾期办结</div>
					<div style="color: #409EFF;" class="f-30">{{info.delay_done}}<span class="f-12 mg-l-5">件</span></div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">
					<div style="color: #409EFF;" class="f-14">逾期率</div>
					<div style="color: #409EFF;" class="f-30">{{info.need_done > 0 ? (((info.delay_done / info.need_done) * 100).toFixed(2)) : '0.00'}}<span class="f-12 mg-l-5">%</span></div>
				</div>
			</el-col>
<!--			<el-col :span="3">-->
<!--				<div class="pd-15 bd-radius-6" style="border:1px solid #409EFF">-->
<!--					<div style="color: #5b5b5b;" class="f-14">已终止</div>-->
<!--					<div style="color: #5b5b5b;" class="f-30">{{info.is_stop}}</div>-->
<!--				</div>-->
<!--			</el-col>-->
		</el-row>
		<el-row :gutter="20" class="mg-b-20">
			<el-col :span="8" v-if="info">
				<el-card shadow="never" class="diy-card" body-style="padding:0">
					<div slot="header" class="cl">
						<span class="f-14">办理事项TOP10</span>
					</div>
					<el-table :data="info.dept_count_top" size="mini" border height="397px">
						<el-table-column label="排名" width="50px" align="center">
							<template slot-scope="scope">
								<el-tag type="danger" effect="dark" size="mini" v-if="scope.$index === 0">{{scope.$index + 1}}</el-tag>
								<el-tag type="warning" effect="dark" size="mini" v-else-if="scope.$index === 1">{{scope.$index + 1}}</el-tag>
								<el-tag type="success" effect="dark" size="mini" v-else-if="scope.$index === 2">{{scope.$index + 1}}</el-tag>
								<el-tag type="info" effect="dark" size="mini" v-else>{{scope.$index + 1}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="部门名称" prop="name"></el-table-column>
						<el-table-column label="办理数量" prop="counts"></el-table-column>
					</el-table>
				</el-card>
			</el-col>
			<el-col :span="8" v-if="info">
				<el-card shadow="never" class="diy-card" body-style="padding:0">
					<div slot="header" class="cl">
						<span class="f-14">办结事项TOP10</span>
					</div>
					<el-table :data="info.dept_done_top" size="mini" border height="397px">
						<el-table-column label="排名" width="50px" align="center">
							<template slot-scope="scope">
								<el-tag type="danger" effect="dark" size="mini" v-if="scope.$index === 0">{{scope.$index + 1}}</el-tag>
								<el-tag type="warning" effect="dark" size="mini" v-else-if="scope.$index === 1">{{scope.$index + 1}}</el-tag>
								<el-tag type="success" effect="dark" size="mini" v-else-if="scope.$index === 2">{{scope.$index + 1}}</el-tag>
								<el-tag type="info" effect="dark" size="mini" v-else>{{scope.$index + 1}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="部门名称" prop="name"></el-table-column>
						<el-table-column label="办结数量" prop="counts"></el-table-column>
					</el-table>
				</el-card>
			</el-col>
			<el-col :span="8" v-if="info">
				<el-card shadow="never" class="diy-card" body-style="padding:0">
					<div slot="header" class="cl">
						<span class="f-14">汇报数量TOP10</span>
					</div>
					<el-table :data="info.user_todo_top" size="mini" border height="397px">
						<el-table-column label="排名" width="50px" align="center">
							<template slot-scope="scope">
								<el-tag type="danger" effect="dark" size="mini" v-if="scope.$index === 0">{{scope.$index + 1}}</el-tag>
								<el-tag type="warning" effect="dark" size="mini" v-else-if="scope.$index === 1">{{scope.$index + 1}}</el-tag>
								<el-tag type="success" effect="dark" size="mini" v-else-if="scope.$index === 2">{{scope.$index + 1}}</el-tag>
								<el-tag type="info" effect="dark" size="mini" v-else>{{scope.$index + 1}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="姓名" prop="name"></el-table-column>
						<el-table-column label="汇报数量" prop="counts"></el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="mg-b-20">
			<el-col :span="12" v-if="info">
				<el-card shadow="never" class="diy-card">
					<div slot="header" class="cl">
						<span class="f-14">事项类型统计</span>
					</div>
					<el-row :gutter="10">
						<el-col :span="12">
							<lee-pie-echarts
								title="事项类型占比"
								ref="pie1"
								:grid-bottom="30"
								height="300px"
								:title-size="16"
								:show-legend="false"
								label-format="{b}:{d}%"
								label-pos="center"
								:radius="['50%','70%']"></lee-pie-echarts>
						</el-col>
						<el-col :span="12">
							<lee-pie-echarts
								title="事项办结占比"
								ref="pie2"
								:grid-bottom="30"
								height="300px"
								:title-size="16"
								:show-legend="false"
								label-format="{b}:{d}%"
								label-pos="center"
								:radius="['50%','70%']"></lee-pie-echarts>
						</el-col>
					</el-row>
					<el-table :data="info.type_list" size="mini" border>
						<el-table-column label="事项类型" prop="type_str"></el-table-column>
						<el-table-column label="总事项" prop="all_count">
							<template slot-scope="scope">
								<span @click="JumpLink({path:'/program/'+scope.row.type})" class="hand a-link f-u">{{scope.row.all_count}}</span>
							</template>
						</el-table-column>
						<el-table-column label="待领办" prop="state_2"></el-table-column>
						<el-table-column label="正常推进中" prop="state_3"></el-table-column>
						<el-table-column label="已办结" prop="state_4"></el-table-column>
						<el-table-column label="办结率" prop="state_4_percent"></el-table-column>
						<el-table-column label="逾期" prop="pstate_2"></el-table-column>
						<el-table-column label="逾期率" prop="pstate_2_percent"></el-table-column>
					</el-table>
				</el-card>
			</el-col>
			<el-col :span="12" v-if="info">
				<el-card shadow="never" class="diy-card">
					<div slot="header" class="cl">
						<span class="f-14">部门事项共计</span>
					</div>
					<el-row :gutter="10">
						<el-col :span="12">
							<lee-pie-echarts
								title="事项数量占比"
								ref="pie3"
								:grid-bottom="30"
								height="300px"
								:title-size="16"
								:show-legend="false"
								label-format="{b}:{d}%"
								label-pos="center"
								:radius="['50%','70%']"></lee-pie-echarts>
						</el-col>
						<el-col :span="12">
							<lee-pie-echarts
								title="部门办结占比"
								ref="pie4"
								:grid-bottom="30"
								height="300px"
								:title-size="16"
								:show-legend="false"
								label-format="{b}:{d}%"
								label-pos="center"
								:radius="['50%','70%']"></lee-pie-echarts>
						</el-col>
					</el-row>
					<el-table :data="info.dept_list" size="mini" border height="203px">
						<el-table-column label="部门" prop="name"></el-table-column>
						<el-table-column label="总事项" prop="state_need_done"></el-table-column>
						<el-table-column label="正常推进中" prop="state_3"></el-table-column>
						<el-table-column label="已办结" prop="state_4"></el-table-column>
						<el-table-column label="办结率" prop="state_done_percent"></el-table-column>
						<el-table-column label="逾期办结" prop="state_8"></el-table-column>
						<el-table-column label="逾期办结率" prop="state_delay_done_percent"></el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>
		<el-card shadow="never" class="diy-card">
			<div slot="header" class="cl">
				<span class="f-14">新增事项统计</span>
			</div>
			<lee-bar-echarts
				ref="hot_words"
				:grid-bottom="30"
				:height="'400px'"
				:show-legend="true"
				legend-pos="top"
				:show-left="true"
				:show-right="false"></lee-bar-echarts>
		</el-card>
	</div>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			searchForm:{
				dept_id:'',
				time:'month'
			},
			time_type:'month',
			info:null
		}
	},
	created() {
	},
	mounted() {
		this.getTotal();
	},
	methods: {
		JumpLink(e){
			if(e){
				this.$router.push(e);
			}
		},
		onChangeDept(e){
			this.getTotal();
		},
		onChangeTime(e){
			let _time = e.split(',');
			this.searchForm.time = [_time[0],_time[1]];
			this.getTotal();
		},
		onChangeTimeType(e){
			if(e === 'other'){
				this.searchForm.time = '';
			}else{
				this.searchForm.time = e;
				this.getTotal();
			}
		},
		getTotal(){
			let _this = this;
			_this.$http.request({
				url: "/SystemTotal/getTotal",
				datas: {...this.searchForm},
				success(res){
					_this.info = res;
					let ldjb_bar = [];
					let zysx_bar = [];
					let hysx_bar = [];
					let qzfsx_bar = [];
					let xaxis = []
					let pie1 = [];
					let pie2 = [];
					let pie3 = [];
					let pie4 = [];

					for (let i=0;i < res.list.length;i++) {
						ldjb_bar.push(res.list[i].ldjb_count);
						zysx_bar.push(res.list[i].zysx_count);
						hysx_bar.push(res.list[i].hysx_count);
						qzfsx_bar.push(res.list[i].qzfsx_count);

						xaxis.push(res.list[i].time);
					}

					if(_this.$refs['hot_words']) {
						_this.$refs['hot_words'].initData(
							[
								{name: '批示督办', type: 'line', color: '', data: ldjb_bar,dw:'件'},
								{name: '日常督办', type: 'line', color: '', data: zysx_bar,dw:'件'},
								{name: '会议督办', type: 'line', color: '', data: hysx_bar,dw:'件'},
								{name: '政务督办', type: 'line', color: '', data: qzfsx_bar,dw:'件'},
							],
							xaxis
						);
					}

					for (let i=0;i < res.type_list.length;i++){
						pie1.push({name: res.type_list[i].type_str, value: parseInt(res.type_list[i].all_count)});
						pie2.push({name: res.type_list[i].type_str, value: parseInt(res.type_list[i].state_4)});
					}

					_this.$nextTick(()=>{
						if (_this.$refs.pie1) {
							_this.$refs.pie1.initData(pie1, '件');
						}
						if (_this.$refs.pie2) {
							_this.$refs.pie2.initData(pie2, '件');
						}
					})

					for (let i=0;i < res.dept_list.length;i++){
						pie3.push({name: res.dept_list[i].name, value: parseInt(res.dept_list[i].state_need_done)});
						pie4.push({name: res.dept_list[i].name, value: parseInt(res.dept_list[i].state_is_done)});
					}

					_this.$nextTick(()=>{
						if (_this.$refs.pie3) {
							_this.$refs.pie3.initData(pie3, '件');
						}
						if (_this.$refs.pie4) {
							_this.$refs.pie4.initData(pie4, '件');
						}
					})
				}
			});
		}
	}
}
</script>
